<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="4" :sm="24">
              <a-form-item label="订单编号">
                <a-input v-model="queryParam.order_no" placeholder="请输入编号" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
              </a-form-item>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="min-width: 110px">
          {{ (text/100).toFixed(2) }}
        </div>
      </s-table>
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import { orderRefundList } from '@/api/wx_shopmall_order'
export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      loading: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单编号',
          ellipsis: true,
          dataIndex: 'order_no'
        },
        {
          title: '退款编号',
          ellipsis: true,
          dataIndex: 'refund_no'
        },
        {
          title: '退款金额(元)',
          dataIndex: 'refund',
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '退款原因',
          dataIndex: 'reason',
          ellipsis: true
        },
        {
          title: '操作人',
          dataIndex: 'operate_by',
          ellipsis: true
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderRefundList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  }
}
</script>
